import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Image,
  Text,
  VStack,
  Stack,
  useBreakpointValue,
  useColorMode,
  HStack
} from "@chakra-ui/react";

function ProductDetails({ products }) {
  const { productId } = useParams();
  const product = products.find((p) => p.id === Number(productId));
  const [selectedImage, setSelectedImage] = useState(product.images[0]);
  const { colorMode } = useColorMode();

  const direction = useBreakpointValue({ base: "column", md: "row" });

  return (
    <>
      <Stack
        mt={28}
        direction={direction}
        spacing={8}
        align="center"
        justify="center"
        mx="auto"
        maxW={{ base: "90%", md: "80%", lg: "60%" }}
        p={5}
      >
        <VStack w="full" maxW="sm" boxShadow="xl">
          <Image
            src={selectedImage}
            alt={product.name}
            fit="cover"
            align="center"
            w="100%"
            h="auto"
            rounded="xl"
          />
          <HStack spacing={2} pb={2} boxShadow={"2xl"}>
            {product.images.map((image, index) => (
              <Box
                key={index}
                as="button"
                onClick={() => setSelectedImage(image)}
                borderWidth={selectedImage === image ? 2 : 0}
                borderColor={colorMode === "light" ? "gray.800" : "gray.200"}
                rounded={"xl"}
              >
                <Image
                  src={image}
                  alt={`${product.name} ${index + 1}`}
                  boxSize="50px"
                  objectFit="cover"
                  rounded="xl"
                />
              </Box>
            ))}
          </HStack>
        </VStack>

        <VStack w="full" align="start" spacing={4}>
          <Text fontSize="2xl" fontWeight="bold">
            {product.name}
          </Text>
          <Text fontSize="md">{product.description}</Text>
          <Text fontSize="lg" fontWeight="semibold">
            ${product.price}
          </Text>
          <StripeButton stripebuttonkey={product.stripebuttonkey} />
        </VStack>
      </Stack>
    </>
  );
}

export default ProductDetails;

function StripeButton({ stripebuttonkey }) {
  return (
    <stripe-buy-button
      buy-button-id={stripebuttonkey}
      publishable-key={process.env.REACT_APP_STRIPE_KEY}
    ></stripe-buy-button>
  );
}
