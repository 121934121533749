import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import { mode } from "@chakra-ui/theme-tools";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Products from "./pages/products"; // Assicurati di aver creato e esportato il componente Products
import NavBar from "./components/navbar";
import Footer from "./components/footer";
import ProductDetails from "./pages/productdetails";
import { products } from "./pages/products";

const styles = {
  global: (props) => ({
    body: {
      color: mode("gray.800", "whiteAlpha.900")(props),
      bg: mode("#F1EDED", "#121212")(props)
    }
  })
};

const components = {
  Drawer: {
    baseStyle: (props) => ({
      dialog: {
        bg: mode("white", "#121212")(props)
      }
    })
  }
};

const myTheme = extendTheme({
  fonts: {
    body: "SF Pro, sans-serif",
    heading: "SF Pro, sans-serif",
    mono: "SF Pro, monospace"
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false
  },
  colors: {
    brand: {
      500: "#319795", // Ad esempio, colore blu per la modalità chiara
      900: "#2c7a7b" // Ad esempio, colore blu scuro per la modalità scura
    }
  },
  components,
  styles
});

const appleFont = css`
  font-family: "SF Pro Display", "SF Pro Text";
  src: url("./SF-PRO.ttf") format("truetype");
`;

function App() {
  return (
    <ChakraProvider theme={myTheme}>
      <Global styles={appleFont} />
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route
            path="/products/:productId"
            element={<ProductDetails products={products} />}
          />
        </Routes>
        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;
