import React from "react";
import { Box, Text, Flex, IconButton, useColorMode } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";

function Navbar() {
  const { colorMode, toggleColorMode } = useColorMode();

  const navbarStyle = {
    backdropFilter: "blur(10px)",
    backgroundColor:
      colorMode === "light"
        ? "rgba(255, 255, 255, 0.8)"
        : "rgba(23, 25, 35, 0.8)"
    // Aggiungi altre proprietà per personalizzare ulteriormente
  };

  return (
    <Box
      as="nav"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
      borderBottomWidth={colorMode === "light" ? "1px" : "0"}
      borderBottomColor={colorMode === "light" ? "gray.200" : "none"}
      p={4}
      {...navbarStyle}
    >
      <Flex alignItems="center" justifyContent="space-between" w="100%">
        <RouterLink to="/">
          <img
            src="https://i.imgur.com/HTIfpNJ.png"
            alt="logo"
            style={{ width: "50px", height: "50px" }}
          />
        </RouterLink>
        <Flex alignItems="center">
          <RouterLink to="/products">
            <Text fontSize="lg" fontWeight="bold" mr={6}>
              Products
            </Text>
          </RouterLink>
          <IconButton
            icon={colorMode === "light" ? <SunIcon /> : <MoonIcon />}
            onClick={toggleColorMode}
            size={"sm"}
            variant="outline"
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default Navbar;
