import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Image,
  SimpleGrid,
  Heading,
  VStack,
  useColorMode,
  Container,
  useColorModeValue,
  HStack
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
// Considera di usare immagini ad alta risoluzione e rappresentative per i tuoi prodotti
export const products = [
  {
    id: 1,
    name: "Battlepass Ultimate",
    price: 9.99,
    description:
      "A groundbreaking in-game GUI Editor that empowers you to craft and manage multiple BattlePasses seamlessly. With just a single click, you can effortlessly switch between active BattlePasses. Whether you're designing a BattlePass with a handful of levels or an extensive ladder, our intuitive rewards interface adapts instantly, ensuring a smooth and engaging player experience.",
    images: ["/images/battlepass.png"],
    youtubeUrl: "https://youtu.be/9UpA__a6YrE",
    stripebuttonkey: "buy_btn_1OHVVgKDa67CvLGaCRo1CClp"
  },
  {
    id: 2,
    name: "Outpost",
    price: 3.99,
    description:
      "is an innovative Minecraft plugin designed for server admins, offering dynamic combat zones filled with unique monsters. It enhances server engagement with easy customization and robust features like intuitive ConfigGUI and outpost cooldowns, elevating your Minecraft experience to new levels.",
    images: ["/images/outpost.png"],
    stripebuttonkey: "buy_btn_1OHwArKDa67CvLGa14JzLEva"
  },
  {
    id: 3,
    name: "Shop Ultimate",
    price: 1.99,
    description:
      "is a modern plugin for creating and adding shops to your server quickly and easily through a GUI. It also includes a checker that automatically finds any issues such as a sellPrice greater than the BuyPrice or some items not set up to ensure that these problems do not occur on your server!",
    images: ["/images/shop.png"],
    stripebuttonkey: "buy_btn_1OHwIPKDa67CvLGayM8cdDK3"
  },
  {
    id: 4,
    name: "Shrine",
    price: 2.99,
    description:
      " is a plugin that allows you to add shrines throughout your map. These shrines can be unlocked through a permission, giving you the ability to connect them to any plugin. Additionally, it will support future quest or dungeon plugins, allowing you to link them together.",
    images: ["/images/shrine.png"],
    stripebuttonkey: "buy_btn_1OHwHQKDa67CvLGazIAUXx3l"
  }
];
function ProductCard({ images, name, price, description, id, youtubeUrl }) {
  const { colorMode } = useColorMode();
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const textColor = "white";
  const buttonStyles = {
    bg: "white", // Pulsante nero su light mode, bianco su dark mode
    color: "black", // Testo inverso rispetto al pulsante
    _hover: {
      bg: colorMode === "light" ? "gray.800" : "gray.200", // Sfondo scuro al passaggio del mouse
      transform: "translateY(-2px)", // Solleva il pulsante al passaggio del mouse
      boxShadow: "xl" // Ombra più profonda al passaggio del mouse
    }
  };
  return (
    <VStack
      p={4}
      bg="black"
      boxShadow="2xl"
      rounded="2xl"
      transition="transform 0.3s, box-shadow 0.3s"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "lg"
      }}
      spacing={3}
      align="start"
      role="group"
    >
      <Box
        pos="relative"
        height="200px"
        rounded="xl"
        overflow="hidden"
        width="100%"
      >
        <Image
          src={selectedImage}
          alt={`Picture of ${name}`}
          objectFit="cover"
          height="100%"
          width="100%"
          transition="transform 0.3s"
          _groupHover={{
            transform: "scale(1.05)"
          }}
        />
        <HStack spacing={2} mt={2} overflowX="auto">
          {images.map((image, index) => (
            <Box
              key={index}
              as="button"
              onClick={() => setSelectedImage(image)}
              borderWidth="1px"
              borderColor={selectedImage === image ? "black" : "transparent"}
            >
              <Image
                src={image}
                alt={`Thumbnail of ${name} ${index + 1}`}
                boxSize="50px"
                objectFit="cover"
                rounded="md"
              />
            </Box>
          ))}
        </HStack>
      </Box>
      <Text fontWeight="bold" fontSize="xl" color={textColor}>
        {name}
      </Text>
      <Text fontSize="lg" color="gray.500">
        €{price.toFixed(2)}
      </Text>
      <Text fontSize="md" noOfLines={2} color={textColor}>
        {description}
      </Text>
      <Button
        {...buttonStyles}
        as={Link}
        to={`/products/${id}`}
        borderRadius="full"
        fontSize="md"
        fontWeight="bold"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      >
        Buy Now
      </Button>
    </VStack>
  );
}

function Products() {
  const textColor = useColorModeValue("gray.800", "white");

  return (
    <Container maxW="container.xl" py={10} px={6} mt="28">
      <VStack spacing={8} align="center" mb={10}>
        <Heading as="h1" size="2xl" fontWeight="bold" color={textColor}>
          The Latest Innovations
        </Heading>
        <Text fontSize="lg" color={textColor}>
          Your favorite products from our selection.
        </Text>
      </VStack>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
        {products.map((product) => (
          <ProductCard
            key={product.id}
            id={product.id}
            images={product.images}
            name={product.name}
            price={product.price}
            description={product.description}
            stripebuttonkey={product.stripebuttonkey} // Corrected to match the key in the products array
            youtubeUrl={product.youtubeUrl}
          />
        ))}
      </SimpleGrid>
    </Container>
  );
}

export default Products;
