import {
  Box,
  VStack,
  Text,
  Button,
  useColorMode,
  Container,
  useColorModeValue,
  useBreakpointValue
} from "@chakra-ui/react";

function Hero() {
  const { colorMode } = useColorMode();
  const headingSize = useBreakpointValue({ base: "5xl", md: "6xl", lg: "6xl" }); // Dimensioni più grandi per i titoli
  const textSize = useBreakpointValue({ base: "md", md: "lg" }); // Testo più grande per dispositivi più grandi
  const buttonSize = useBreakpointValue({ base: "md", md: "lg" }); // Pulsanti più grandi per dispositivi più grandi
  const navbarHeight = "60px"; // Sostituisci con l'altezza effettiva della tua navbar
  const heroBackgroundColor = useColorModeValue("gray.50", "black");

  // Stili aggiuntivi per il pulsante
  const buttonStyles = {
    bg: colorMode === "light" ? "black" : "white", // Pulsante nero su light mode, bianco su dark mode
    color: colorMode === "light" ? "white" : "black", // Testo inverso rispetto al pulsante
    _hover: {
      bg: colorMode === "light" ? "gray.800" : "gray.200", // Sfondo scuro al passaggio del mouse
      transform: "translateY(-2px)", // Solleva il pulsante al passaggio del mouse
      boxShadow: "xl" // Ombra più profonda al passaggio del mouse
    }
  };

  return (
    <Box mt={navbarHeight} py={20} bg={heroBackgroundColor} w="full">
      <Container centerContent>
        <VStack spacing={6} align="center" maxW="2xl" textAlign="center">
          <Text fontSize={headingSize} fontWeight="bold" letterSpacing="wide">
            Elevate Your Ideas
          </Text>
          <Text fontSize={textSize} opacity={0.85} px={4}>
            At AnotherLevel, we push the boundaries of web development and
            Minecraft plugin creation to bring your projects to life.
          </Text>
          <Button
            {...buttonStyles}
            size={buttonSize}
            as="a"
            href="https://docs.anotherlevel.it/"
            borderRadius="full"
            px={10} // Padding orizzontale aumentato
            py={8} // Padding verticale aumentato
            fontSize="md"
            fontWeight="bold"
            transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
          >
            Get Started
          </Button>
        </VStack>
      </Container>
    </Box>
  );
}

export default Hero;
