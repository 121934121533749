import React from "react";
import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  HStack,
  VStack,
  Divider,
  useColorModeValue
} from "@chakra-ui/react";

function Footer() {
  const footerBgColor = useColorModeValue("gray.900", "black");
  const footerTextColor = useColorModeValue("white", "gray.200");

  return (
    <Box bg={footerBgColor} color={footerTextColor} width="100%" mt={28}>
      <Container
        as={Stack}
        maxW={"6xl"}
        py={10}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <HStack spacing={8} alignItems="start">
          <VStack align="start">
            <Text fontWeight="bold" mb={2}>
              Socials
            </Text>
            <Link href="https://discord.gg/S8GjzxWjmP" isExternal>
              Discord
            </Link>
            <Link href="https://github.com/RickyLaChow" isExternal>
              Github
            </Link>
          </VStack>
          <VStack align="start">
            <Text fontWeight="bold" mb={2}>
              Find Our Products
            </Text>
            <Link
              href="https://www.spigotmc.org/resources/authors/rickylachow.462796/"
              isExternal
            >
              SpigotMC
            </Link>
            <Link href="https://polymart.org/user/rickylachow.9149" isExternal>
              Polymart
            </Link>
            <Link
              href="https://builtbybit.com/creators/rickylachow.216028/"
              isExternal
            >
              BuiltByBit
            </Link>
            <Link href="/products">Our Products</Link>
          </VStack>
        </HStack>
        <Divider my={4} />
        <Text textAlign="center" width="full">
          © {new Date().getFullYear()} AnotherLevel. All rights reserved.
        </Text>
      </Container>
    </Box>
  );
}

export default Footer;
