import React from "react";
import Hero from "../components/hero";
import Products from "./products";
export default function Home() {
  return (
    <>
      <Hero />
      <Products />
    </>
  );
}
